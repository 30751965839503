<!-- 科技新闻 -->
<template>
  <div>
    <div v-show="show" class="notice-body">
      <ul class="notice-list">
        <li>
          <a
            @click="isshow"
            class="title"
            title="百度布局移动互联网 推出更多无线产品"
          >
            百度布局移动互联网 推出更多无线产品
          </a>
          <a href="" class="desc">
            &nbsp;&nbsp;&nbsp;&nbsp;
            记者昨日获悉，百度已正式推出“掌上百度”和“百度手机输入法”，目标直指近2亿手机网民。百度方面表示，期望手机网民能像PC端网民一样，能够更便捷地获取信...
          </a>
          <span class="news-list-item-date">2022-05-05</span>
        </li>
      </ul>
    </div>

    <div v-show="!show" class="notice-body">
      <a href="#" @click="isshow" class="notice-back"
        >&lt;&nbsp;返回新闻公共列表</a
      >
      <div class="notice-show">
        <div class="title">
          <h3>百度布局移动互联网 推出更多无线产品</h3>
          <span>发布时间：2022-05-05 09:31:44</span>
        </div>
        <div class="info">
          <p>
            <span style="font-size: 14px; font-family: 微软雅黑"
              ><span
                style="
                  background-color: #ffffff;
                  color: #565656;
                  font-size: 14px;
                  font-family: 微软雅黑;
                "
              ></span
              >&nbsp;&nbsp;&nbsp;&nbsp;
              记者昨日获悉，百度已正式推出“掌上百度”和“百度手机输入法”，目标直指近2亿手机网民。百度方面表示，期望手机网民能像PC端网民一样，能够更便捷地获取信息。</span
            ><br />
            <br />
            <span style="font-size: 14px; font-family: 微软雅黑"
              ><span
                style="
                  background-color: #ffffff;
                  color: #565656;
                  font-size: 14px;
                  font-family: 微软雅黑;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
              >据百度客户端业务部总监王啸介绍，“掌上百度”采用了压缩及Cache技术，并具备了拍照上传、减少验证码和身份记忆功能；而“百度手机输入法”比普通手机输入法增加40%的超大词库，还结合百度的“点划结合”专利技术。</span
            ><br />
            <br />
            <span style="font-size: 14px; font-family: 微软雅黑"
              ><span
                style="
                  background-color: #ffffff;
                  color: #565656;
                  font-size: 14px;
                  font-family: 微软雅黑;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
              >最新数据显示，手机网民数量突破1.92亿，几乎占据了中国网民数量的半壁江山，用手机上网的用户习惯正在形成。百度市场和商务拓展副总裁任旭阳表示，移动互联网是百度未来战略的重要组成部分，未来百度还将不断关注移动互联网的特点，推出更多无线产品。</span
            ><br />
          </p>
        </div>
        <br />
        <!-- <div class="media-link">
              <div class="link-box">
                  本文链接：<span class="link-url-span" id="linkUrl">http://i360.cn/news/content/1.html</span>
                  <span class="copy-url-btn" id="copyUrlBtn" data-clipboard-target="linkUrl"
                      data-clipboard-text="http://i360.cn/news/content/1.html">
                      复制本文链接
                  </span>
                  <i class="l-tips">链接已复制</i>
              </div>
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    if (this.$route.params.type) {
      this.show = false;
    }
  },
  methods: {
    isshow() {
      this.show = !this.show;
    },
  },
};
</script>

<style scoped></style>
